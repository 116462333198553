<script lang="ts">
  import { page } from '$app/stores';
  import Icon from '$lib/components/icon/icon.svelte';
  import NavigationActions from './navigation-actions.svelte';
  import ResourcesLinks from './resources-links.svelte';

  export let closeNav: () => void = () => {};
  let resourceNavOpen = false;
  $: path = $page.url.pathname;
</script>

<li class="flex gap-6 items-center xl:hidden">
  <NavigationActions />
</li>
<li class="nav-link" class:active={path.startsWith('/product')}>
  <a on:click={closeNav} href="/product">Product</a>
</li>
<li class="nav-link" class:active={path.startsWith('/how-it-works')}>
  <a on:click={closeNav} href="/how-it-works">How it Works</a>
</li>
<li class="nav-link">
  <a target="_blank" href="https://docs.temporal.io">Docs</a>
</li>
<li class="nav-link" class:active={path.startsWith('/cloud')}>
  <a on:click={closeNav} href="/cloud">Cloud</a>
</li>
<li class="nav-link" class:active={path.startsWith('/pricing')}>
  <a on:click={closeNav} href="/pricing">Pricing</a>
</li>
<li class="nav-link" class:active={path.startsWith('/in-use')}>
  <a on:click={closeNav} href="/in-use">Use Cases</a>
</li>
<li
  class="nav-link resources-link-lg"
  on:mouseenter={() => (resourceNavOpen = true)}
  on:mouseleave={() => (resourceNavOpen = false)}
  on:focus={() => (resourceNavOpen = true)}
  on:focusout={() => (resourceNavOpen = false)}
>
  Resources
  <ResourcesLinks isOpen={resourceNavOpen} {closeNav} />
</li>
<li class="nav-link resources-link-sm">
  <button
    class="w-full flex justify-between items-center pr-6"
    on:click={() => (resourceNavOpen = !resourceNavOpen)}
  >
    Resources
    <Icon name={resourceNavOpen ? 'hyphen' : 'add'} />
  </button>
  <ResourcesLinks isOpen={resourceNavOpen} {closeNav} />
</li>
<li class="nav-link">
  <a target="_blank" href="https://replay.temporal.io/">Replay 2024</a>
</li>
<li class="nav-link">
  <a target="_blank" href="https://github.com/temporalio">
    <Icon name="github" />
  </a>
</li>
<li class="flex items-center gap-2 max-xl:hidden">
  <NavigationActions />
</li>

<style lang="postcss">
  .nav-link {
    @apply relative h-full flex items-center border-b-2 border-transparent max-xl:items-start max-xl:text-left;
  }

  .nav-link a {
    @apply h-full flex items-center;
  }

  .nav-link:hover {
    @apply text-indigo-200 transition-colors;
  }

  .nav-link.active {
    @apply border-green-300 w-fit;
  }

  .resources-link-lg {
    @apply max-xl:hidden;
  }

  .resources-link-sm {
    @apply flex-col xl:hidden;
  }
</style>
