<script lang="ts">
  import { page } from '$app/stores';

  export let closeNav: () => void = () => {};
  export let isOpen: boolean;
  $: path = $page.url.pathname;

  interface ResourceLink {
    links: {
      copy: string;
      href: string;
      target: '_blank' | '_self';
    }[];
  }

  const resourceLinks: ResourceLink[] = [
    {
      links: [
        {
          copy: 'Resource Library',
          href: '/resources',
          target: '_self',
        },
        {
          copy: 'Learn Temporal',
          href: 'https://learn.temporal.io/',
          target: '_blank',
        },
        {
          copy: 'Community',
          href: '/community',
          target: '_self',
        },
        {
          copy: 'Blog',
          href: '/blog',
          target: '_self',
        },
        {
          copy: 'For Startups',
          href: '/startup',
          target: '_self',
        },
        {
          copy: 'Partners',
          href: '/partners',
          target: '_self',
        },
        {
          copy: 'Change Log',
          href: '/change-log',
          target: '_self',
        },
      ],
    },
  ];
</script>

{#if isOpen}
  <ul class="resource-nav">
    {#each resourceLinks as { links }}
      <li>
        <ul class="resource-sub-nav">
          {#each links as { copy, href, target }}
            <li class="resource-link" class:active={path === href}>
              <a on:click={closeNav} {href} {target}>{copy}</a>
            </li>
          {/each}
        </ul>
      </li>
    {/each}
  </ul>
{/if}

<style lang="postcss">
  .resource-nav {
    @apply flex flex-col gap-8 bg-space-black text-white top-6 p-8 max-xl:p-0 max-xl:pt-4 max-xl:pl-4 xl:absolute 2xl:top-20 xl:rounded-b-lg xl:drop-shadow-lg xl:transform-gpu xl:z-50;
  }

  .resource-sub-nav {
    @apply flex flex-col whitespace-nowrap gap-3;
  }

  .resource-link {
    @apply border-b-2 border-transparent;
  }

  .resource-link:hover {
    @apply text-indigo-200 transition-colors;
  }

  .resource-link.active {
    @apply border-green-300;
  }
</style>
