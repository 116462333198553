<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
</script>

<Button href="/get-cloud" class="whitespace-nowrap">Sign Up</Button>
<Button
  variant="secondary"
  href="https://cloud.temporal.io/"
  target="_blank"
  class="whitespace-nowrap"
>
  Log In
</Button>
